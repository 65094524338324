
              @import "@/assets/css/variables.scss";
            
















































































































































































// @import '@/assets/css/pages/referralLinks.scss';
.copy_line_box {
  display: flex;
  justify-content: space-between;

  .link_item {
    position: relative;
    height: 156px;
    background: #f8f8fd;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    margin-bottom: 40px;

    @include screen-mobile {
      margin-bottom: 24px;
    }

    .content {
      padding: 16px 40px;

      p:nth-child(1) {
        font-size: 16px;
        color: #1d1d1f;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 24px;
      }

      p:nth-child(2) {
        font-size: 14px;
        color: #6e6e73;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    &:hover,
    &:focus,
    &:active {
      .shadow {
        animation: shadowShow 0.2s linear forwards;
      }
    }

    .shadow {
      width: 100%;
      position: absolute;
      bottom: -100%;
      height: 100%;
      background: rgba(29, 29, 31, 0.68);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .copy {
        span {
          display: inline-block;
          font-size: 16px;
          color: #1d1d1f;
          line-height: 24px;
          font-weight: 500;
          background: $white;
          padding: 8px 30px;
          border-radius: 8px;
          cursor: pointer;
        }
      }

      .code {
        img {
          width: 100px;
          height: 100px;
          border-radius: 8px;
        }
      }
    }
  }
}

@keyframes shadowShow {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0%;
  }
}

@-webkit-keyframes shadowShow {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0%;
  }
}
